import { ResignReasonsDto, ResignItemDto } from './resign-reasons.dto';

export class ResignReasons {
    didntGo: ResignItem[];
    resign: ResignItem[];
    constructor(data: ResignReasonsDto) {
        this.didntGo = data.didntGo.map((item) => new ResignItem(item));
        this.resign = data.resign.map((item) => new ResignItem(item));
    }
}

export class ResignItem {
    name: string;
    value: number;
    constructor(data: ResignItemDto) {
        this.name = `${data.value}. ${data.name}`;
        this.value = data.value;
    }
}
