import { Injectable, inject } from '@angular/core';
import { of, catchError, map, mergeMap } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { HttpService } from '../services/http.service';
import { ResignReasonsActions } from '../actions/resign-reasons.actions';

@Injectable()
export class ResignReasonsEffects {
    private actions$ = inject(Actions);
    private httpService = inject(HttpService);

    getResignReasons$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ResignReasonsActions.getResignReasons),
            mergeMap(() => {
                return this.httpService.getResignReasons().pipe(
                    map((payload) => ResignReasonsActions.getResignReasonsSuccess({ payload })),
                    catchError(() => of(ResignReasonsActions.getResignReasonsError())),
                );
            }),
        );
    });
}
