import { ChangeDetectionStrategy, Component, Input, OnInit, inject } from '@angular/core';
import { ControlContainer, FormGroup } from '@angular/forms';
import { ResignReasonsFacade } from '@app/store/resign-reasons';
// #FIXME: Import place
import { PassengerUpdateFormGroup } from '@app/services/modules/booking/models/form.value';
import { ParcelUpdateFormGroup } from '@app/services/modules/parcel/models/form.value';

@Component({
    selector: 'app-form-resign-reason',
    templateUrl: './form-resign-reason.component.html',
    styleUrls: ['./form-resign-reason.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormResignReasonComponent implements OnInit {
    private readonly controlContainer = inject(ControlContainer);
    private readonly resignReasonsFacade = inject(ResignReasonsFacade);
    @Input() transactionIndex!: number;

    resignReasonsSelectOptions$ = this.resignReasonsFacade.resignReasonsSelectOptions$;

    form!: FormGroup<PassengerUpdateFormGroup> | FormGroup<ParcelUpdateFormGroup>;

    ngOnInit(): void {
        this.form = <FormGroup<PassengerUpdateFormGroup> | FormGroup<ParcelUpdateFormGroup>>this.controlContainer.control;
    }

    name() {
        if (this.isPassenger(this.form)) {
            return `${this.form.value.personalDetails?.firstname || ''} ${this.form.value.personalDetails?.surname || ''}`.trim();
        }
        return `${this.form.value.recipient?.firstname || ''} ${this.form.value.recipient?.surname || ''}`.trim();
    }

    isPassenger(form: FormGroup<PassengerUpdateFormGroup> | FormGroup<ParcelUpdateFormGroup>): form is FormGroup<PassengerUpdateFormGroup> {
        return (form as FormGroup<PassengerUpdateFormGroup>).value.personalDetails !== undefined;
    }
}
