import { createReducer, on } from '@ngrx/store';
import { ResignReasonsActions } from '../actions/resign-reasons.actions';
import { ResignReasons } from '../models';

export interface ResignReasonsState {
    loading: boolean | null;
    loaded: boolean | null;
    reasons: ResignReasons | null;
}

export const initialState: ResignReasonsState = {
    loading: null,
    loaded: null,
    reasons: null,
};

export const ResignReasonsReducer = createReducer(
    initialState,

    on(
        ResignReasonsActions.getResignReasons,
        (state): ResignReasonsState => ({
            ...state,
            loaded: false,
            loading: true,
        }),
    ),
    on(
        ResignReasonsActions.getResignReasonsSuccess,
        (state, action): ResignReasonsState => ({
            ...state,
            reasons: action.payload,
            loaded: true,
            loading: false,
        }),
    ),
    on(
        ResignReasonsActions.getResignReasonsError,
        (state): ResignReasonsState => ({
            ...state,
            loaded: false,
            loading: false,
        }),
    ),
    on(
        ResignReasonsActions.clear,
        (): ResignReasonsState => ({
            ...initialState,
        }),
    ),
);
