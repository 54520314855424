<div class="text-start">
    <div class="pt-3">
        <div class="d-block">
            <app-form-switch formControlGroup="didntGo" label="{{ 'BOOKINGS.FORM.didnt_go' | translate }}" direction="column"></app-form-switch>
        </div>

        <div @Grow *ngIf="this.form.controls.didntGo.value === true">
            <app-form-dropdown
                formControlGroup="didntGoReason"
                label="Powód 'Nie pojechał'"
                placeholder="Wybierz z listy"
                [options]="(didntGoReasonsSelectOptions$ | async)!"
                [block]="true"
                class="pt-5"
            />
        </div>
    </div>
</div>
