import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { Grow } from '@app/shared/animations';

@Component({
    selector: 'app-services-date-history',
    templateUrl: './services-date-history.component.html',
    styleUrls: ['./services-date-history.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [Grow],
})
export class ServicesDateHistoryComponent {
    @Input() translationScope!: string;
    @Input() dateHistory!: string[];

    showHistory = false;

    onShowHistoryClick(event: MouseEvent | KeyboardEvent) {
        event.preventDefault();
        this.showHistory = !this.showHistory;
    }
}
