import { Pipe, PipeTransform, inject } from '@angular/core';
import { OrderFacade } from '@app/store/order';
import { Observable, filter, map } from 'rxjs';

@Pipe({
    name: 'bookingDateHistory',
})
export class BookingDateHistoryPipe implements PipeTransform {
    orderFacade$ = inject(OrderFacade);
    transform(id: string | null): Observable<string[]> {
        return this.orderFacade$.order$.pipe(
            filter(() => id !== null),
            map((order) => {
                return order.bookings.find((booking) => booking.id === id)?.dateHistory || [];
            }),
        );
    }
}
