import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, map, take, tap } from 'rxjs';

import { ResignItem, ResignReasons } from '../models';
import { ResignReasonsActions } from '../actions/resign-reasons.actions';
import { selectResignReasons, selectLoaded, selectLoading } from '../selectors/resign-reasons.selectors';

@Injectable({
    providedIn: 'root',
})
export class ResignReasonsFacade {
    private store = inject(Store);

    loaded$ = this.store.select(selectLoaded);
    loading$ = this.store.select(selectLoading);
    resignReasons$ = this.store.select(selectResignReasons).pipe(filter((reasons): reasons is ResignReasons => reasons !== null));

    resignReasonsSelectOptions$ = this.resignReasons$.pipe(
        map((reasons) =>
            reasons.resign.map((reason) => ({
                label: `${reason.name}`,
                value: reason.value,
            })),
        ),
    );

    didntGoReasonsSelectOptions$ = this.resignReasons$.pipe(
        map((reasons) =>
            reasons.didntGo.map((reason) => ({
                label: `${reason.name}`,
                value: reason.value,
            })),
        ),
    );

    resignReasons() {
        let value: ResignItem[] = [];
        this.store
            .select(selectResignReasons)
            .pipe(
                take(1),
                tap((reasons) => {
                    if (reasons?.resign) {
                        value = reasons.resign;
                    }
                }),
            )
            .subscribe();
        return value;
    }

    didntGoReasons() {
        let value: ResignItem[] = [];
        this.store
            .select(selectResignReasons)
            .pipe(
                take(1),
                tap((reasons) => {
                    if (reasons?.didntGo) {
                        value = reasons.didntGo;
                    }
                }),
            )
            .subscribe();
        return value;
    }

    getResignReasons() {
        this.store.dispatch(ResignReasonsActions.getResignReasons());
    }

    clear() {
        this.store.dispatch(ResignReasonsActions.clear());
    }
}
