import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { ResignReasons, ResignReasonsDto } from '../models';

import { Observable, map } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    private readonly httpClient = inject(HttpClient);

    getResignReasons(): Observable<ResignReasons> {
        return this.httpClient.get<ResignReasonsDto>(`config/reasons`).pipe(map((response) => new ResignReasons(response)));
    }
}
