import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { RESIGN_REASONS_FEATURE_KEY } from './keys';
import { ResignReasonsReducer } from './reducers/resign-reasons.reducer';
import { EffectsModule } from '@ngrx/effects';
import { ResignReasonsEffects } from './effects/resign-reasons.effect';

@NgModule({
    imports: [StoreModule.forFeature(RESIGN_REASONS_FEATURE_KEY, ResignReasonsReducer), EffectsModule.forFeature([ResignReasonsEffects])],
})
export class ResignReasonsModule {}
