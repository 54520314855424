import { Pipe, PipeTransform, inject } from '@angular/core';
import { OrderFacade } from '@app/store/order';
import { Observable, filter, map } from 'rxjs';

@Pipe({
    name: 'bookingNumber',
})
export class BookingNumberPipe implements PipeTransform {
    orderFacade$ = inject(OrderFacade);
    transform(id: string | null): Observable<number | null> {
        return this.orderFacade$.order$.pipe(
            filter(() => id !== null),
            map((order) => order.bookings.find((booking) => booking.id === id)?.externalId || null),
        );
    }
}
