<div class="fs-14">
    <div class="d-flex align-items-center pointer" (click)="onShowHistoryClick($event)" (keypress)="onShowHistoryClick($event)" tabindex="1">
        <span class="me-2" *ngIf="dateHistory.length > 3" appIcon="exclamation-triangle" color="danger" size="5"></span>
        <span>{{ translationScope + 'FORM.date_history' | translate: { count: dateHistory ? dateHistory.length : 0 } }}</span>
        <span class="ms-4">
            <span *ngIf="!showHistory" appIcon="chevron-down" color="default" size="5"></span>
            <span *ngIf="showHistory" appIcon="chevron-up" color="default" size="5"></span>
        </span>
    </div>
    <div @Grow *ngIf="showHistory">
        <ul class="mb-0 unstyled pt-2">
            <li class="p-pl-1" *ngFor="let date of dateHistory">- {{ date | date: 'dd.MM.yyyy' }}</li>
        </ul>
    </div>
</div>
