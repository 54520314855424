<div class="box-popup">
    <div class="title mb-12">{{ 'MODAL.confirmation' | translate }}</div>

    <ng-container *ngFor="let message of data.messages">
        <div class="text-center">
            <span appIcon="exclamation-triangle" color="danger" size="6" class="mb-4"></span>
            <div class="tw-mb-6">{{ message }}</div>
        </div>
    </ng-container>
    <div class="row">
        <div class="col-6">
            <p-button (click)="onConfirmClick($event)" styleClass=" p-button-success w-100" label="{{ data.confirmKey | translate }}" />
        </div>
        <div class="col-6">
            <p-button (click)="onCloseClick($event)" styleClass=" p-button-danger w-100" label="{{ data.cancelKey | translate }}" />
        </div>
    </div>
</div>
