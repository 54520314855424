import { ViewChild, ElementRef, Component } from '@angular/core';
import { OpenClose, PopupBaseComponent } from '@app/shared/popup';
@Component({
    selector: 'app-prerequisities-warning-popup',
    templateUrl: './prerequisities-warning-popup.component.html',
    styleUrls: ['./prerequisities-warning-popup.component.scss'],
    animations: [OpenClose],
})
export class PrerequisitiesWarningPopupComponent extends PopupBaseComponent {
    @ViewChild('popup') boxRef!: ElementRef<HTMLDivElement>;

    override data!: { messages: string[]; confirmKey: string; cancelKey: string };

    onCloseClick(event: MouseEvent | KeyboardEvent): void {
        event.preventDefault();
        this.close('close');
    }

    onConfirmClick(event: MouseEvent | KeyboardEvent) {
        event.preventDefault();
        this.close('confirmed');
    }
}
