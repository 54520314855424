import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { ControlContainer, FormGroup } from '@angular/forms';
import { ResignReasonsFacade } from '@app/store/resign-reasons';
import { startWith, tap } from 'rxjs';
// #FIXME: Import place
import { PassengerUpdateFormGroup } from '@app/services/modules/booking/models/form.value';
import { Grow } from '@app/shared/animations';

@Component({
    selector: 'app-form-didntgo-reason',
    templateUrl: './form-didntgo-reason.component.html',
    styleUrls: ['./form-didntgo-reason.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [Grow],
})
export class FormDidntGoReasonComponent implements OnInit {
    private readonly controlContainer = inject(ControlContainer);
    private readonly resignReasonsFacade = inject(ResignReasonsFacade);

    didntGoReasonsSelectOptions$ = this.resignReasonsFacade.didntGoReasonsSelectOptions$;

    form!: FormGroup<PassengerUpdateFormGroup>;

    ngOnInit(): void {
        this.form = <FormGroup<PassengerUpdateFormGroup>>this.controlContainer.control;
        this.form.controls.didntGo.valueChanges
            .pipe(
                startWith(this.form.controls.didntGo.value),
                tap((value) => {
                    if (value === false) {
                        this.form.controls.didntGoReason.patchValue(null);
                    }
                }),
            )
            .subscribe();
    }
}
