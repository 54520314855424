<app-box [border]="true">
    <h4 class="mb-6 text-oneline">Rezygnacja - {{ name() || (isPassenger(form) ? 'Pasażer' : 'Paczka') }}</h4>
    <app-form-dropdown
        formControlGroup="resignReason"
        placeholder="Wybierz z listy"
        label="Powód rezygnacji"
        [options]="(resignReasonsSelectOptions$ | async)!"
        [block]="true"
    />
</app-box>
