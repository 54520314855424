import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class HighlightService {
    private _highlight$ = new BehaviorSubject<{ orderId: string | null; bookingId: string | null }>({
        orderId: null,
        bookingId: null,
    });

    highlight$ = this._highlight$;

    setHighlight(orderId: string, bookingId?: string) {
        this._highlight$.next({ orderId, bookingId: bookingId ?? null });
    }

    clear() {
        this._highlight$.next({ orderId: null, bookingId: null });
    }
}
